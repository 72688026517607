import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import UIKitLayout from '@/layouts/UIKitLayout.vue'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          breadcrumb: 'Home',
          title: 'Home - d1m Hub',
          metaTags: [
            {
              name: 'D1m4 Hub for small mini apps and tools',
            }
          ]
        },
        // meta: {
        //   breadcrumb: 'Home',
        // },
        component: HomeView
      },
      {
        path: '/bookmarks',
        name: 'Cool websites',
        meta: {
          breadcrumb: 'Home',
          title: 'Cool sites - d1m Hub',
          metaTags: [
            {
              name: 'A collection of powerful or interesting websites.',
              // content: 'This is the homepage of my Vue.js app.'
            }
          ]
        },
        component: () => import('../views/BookmarksView.vue'),
      },
      {
        path: '/great-minds',
        name: 'Great Minds',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GreatMinds.vue'),
      },
      {
        path: '/quotes',
        name: 'Quotes',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/QuotesPage.vue'),
      },
      {
        path: '/language-game',
        name: 'Language Game',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/LanguageGame.vue'),
      },
      {
        path: '/ads/graphs',
        name: 'Graphs and Trees',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GraphsLesson.vue'),
      },
      {
        path: '/graphs',
        name: 'Graphs',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GraphPage.vue'),
      },
      {
        path: '/world-map',
        name: 'World Map',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/WorldMapView.vue'),
      },
      {
        path: '/adt-time-complexity',
        name: 'Abstract Data Types Time Complexity',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/AdtTimeComplexityView.vue'),
      },

      {
        path: '/css-backgrounds',
        name: 'Css Backgrounds',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/TexturesGrid.vue'),
      },


      {
        path: '/saving-calculator',
        name: 'Saving Calculator',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Saving account calculator - d1m Hub',
        },
        component: () => import('../views/CalculateInterest.vue'),
      },
      {
        path: '/caesar-cipher',
        name: 'Caesar Cipher',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Caesar Cipher Encrypt and Decrypt - d1m Hub',
        },
        component: () => import('../views/CaesarCipherView.vue'),
      },

      {
        path: '/binary-search-tree',
        name: 'Binary Search Tree Vizualization',
        meta: {
          breadcrumb: 'Concepts',
          title: 'cross the river game - d1m Hub',
        },
        component: () => import('../views/BinarySearchTree.vue'),
      },
      {
        path: '/sorting-algorithms',
        name: 'Sorting Algorithms Vizualization',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Sorting Algorithms Vizualization - d1m Hub',
        },
        component: () => import('../views/SortingAlgorithms.vue'),
      },

      {
        path: '/window-designer',
        name: 'Window Designer',
        meta: {
          breadcrumb: 'Home',
          title: 'Window Designer - d1m Hub',
        },
        component: () => import('../views/WindowDesigner.vue'),
      },


      {
        path: '/particle-sphere',
        name: 'Particle Sphere',
        meta: {
          breadcrumb: 'Home',
          title: 'Particle Sphere - d1m Hub',
        },
        component: () => import('../views/ParticleSphere.vue'),
      },

      {
        path: '/hourglass',
        name: 'Hourglass',
        meta: {
          breadcrumb: 'Tools',
          title: 'Hourglass - d1m Hub',
        },
        component: () => import('../views/HourGlassView.vue'),
      },
      {
        path: '/bmi-calculator',
        name: 'BMI Calculator',
        meta: {
          breadcrumb: 'Tootls',
          title: 'Body Mass Index Calculator - d1m Hub',
        },
        component: () => import('../views/BMICalculator.vue'),
      },




      {
        path: '/solar-system',
        name: 'Solar System',
        meta: {
          breadcrumb: 'Home',
          title: 'Solar system - d1m Hub',
        },
        component: () => import('../views/SolarSystem.vue'),
      },
      {
        path: '/scroll-to-the-abyss',
        name: 'Scroll to the abyss',
        meta: {
          breadcrumb: 'Home',
          title: 'Scroll to the abyss - d1m Hub',
        },
        component: () => import('../views/ScrollToAbyss.vue'),
      },

      {
        path: '/sleep-calculator',
        name: 'Sleep Calculator',
        meta: {
          breadcrumb: 'Home',
          title: 'Sleep calculator - d1m Hub',
        },
        component: () => import('../views/SleepCalculator.vue'),
      },


      {
        path: '/developer-checklist',
        name: 'Developer Checklist',
        meta: {
          breadcrumb: 'Home',
          title: 'Developer Checklist - d1m Hub',
        },
        component: () => import('../views/DeveloperChecklist.vue'),
      },
      
      {
        path: '/minifier',
        name: 'Minifier',
        meta: {
          breadcrumb: 'Home',
          title: 'Minifier - d1m Hub',
        },
        component: () => import('../views/MinifierView.vue'),
      },
      {
        path: '/http-status-codes',
        name: 'HTTPStatusCodes',
        meta: {
          breadcrumb: 'Home',
          title: 'http Status Codes explained - d1m Hub',
        },
        component: () => import('../views/HttpStatusCodes.vue'),
      },
      {
        path: '/bootstrap-cheatsheet',
        name: 'Bootstrap Cheatsheet',
        meta: {
          breadcrumb: 'Home',
          title: 'Bootstrap Cheatsheet - d1m Hub',
        },
        component: () => import('../views/BootstrapCheatsheet.vue'),
      },


      // Games
      {
        path: '/sudoku',
        name: 'Sudoku',
        meta: {
          breadcrumb: 'Games',
          title: 'Classic Sudoku - d1m Hub',
        },
        component: () => import('../views/games/SudokuGame.vue'),
      },
      {
        path: '/thief',
        name: 'Thief Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Catch the thief game - d1m Hub',
        },
        component: () => import('../views/games/ThiefGame.vue'),
      },
      {
        path: '/cabbage-sheep-wolf',
        name: 'Cabbage sheep wolf Game',
        meta: {
          breadcrumb: 'Games',
          title: 'cross the river game - d1m Hub',
        },
        component: () => import('../views/games/SheepGame.vue'),
      },
      {
        path: '/roulette',
        name: 'Roulette Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Try win the casino roulette - d1m Hub',
        },
        component: () => import('../views/games/RouletteGame.vue'),
      },
      {
        path: '/black-jack',
        name: 'Blackjack Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Black jack game - d1m Hub',
        },
        component: () => import('../views/games/BlackjackGame.vue'),
      },
      {
        path: '/slot-machine',
        name: 'Slot Machine',
        meta: {
          breadcrumb: 'Games',
          title: 'Slot Machine - d1m Hub',
        },
        component: () => import('../views/games/SlotMachineGame.vue'),
      },
      {
        path: '/memory-game',
        name: 'Memory Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Memory Game - d1m Hub',
        },
        component: () => import('../views/games/MemoryGame.vue'),
      },
      {
        path: '/flash-cards',
        name: 'Flash Cards',
        meta: {
          breadcrumb: 'Games',
          title: 'Flash Caeds - d1m Hub',
        },
        component: () => import('../views/QuestionsView.vue'),
      },



      {
        path: 'about',
        name: 'About',
        meta: {
          breadcrumb: 'About',
          // requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      },

      {
        path: '/changelog',
        name: 'Changelog',
        meta: {
          title: 'Changelog - d1m Hub',
        },
        component: () => import('../views/ChangelogView.vue'),
      },
    ]
  },

  //
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  //
  {
    path: '/ui_kit/',
    component: UIKitLayout,
    children: [
      {
        path: 'typography',
        name: 'TypographyView',
        component: () => import('../views/ui_kit/TypographyView.vue')
      },
      {
        path: 'icon-font',
        name: 'IconFontView',
        component: () => import('../views/ui_kit/IconFontView.vue')
      },
      {
        path: 'code',
        name: 'CodeView',
        component: () => import('../views/ui_kit/CodeView.vue')
      },
      {
        path: 'images',
        name: 'ImagesView',
        component: () => import('../views/ui_kit/ImagesView.vue')
      },
      {
        path: 'tables',
        name: 'TablesView',
        component: () => import('../views/ui_kit/TablesView.vue')
      },
      {
        path: 'accordion',
        name: 'AccordionView',
        component: () => import('../views/ui_kit/AccordionView.vue')
      },
      {
        path: 'alerts',
        name: 'AlertsView',
        component: () => import('../views/ui_kit/AlertsView.vue')
      },
      {
        path: 'badges',
        name: 'BadgesView',
        component: () => import('../views/ui_kit/BadgesView.vue')
      },
      {
        path: 'blog',
        name: 'BlogComponentsView',
        component: () => import('../views/ui_kit/BlogComponentsView.vue')
      },
      {
        path: 'breadcrumbs',
        name: 'BreadcrumbsView',
        component: () => import('../views/ui_kit/BreadcrumbsView.vue')
      },
      {
        path: 'buttons',
        name: 'ButtonsView',
        component: () => import('../views/ui_kit/ButtonsView.vue')
      },
      {
        path: 'button-group',
        name: 'ButtonGroupView',
        component: () => import('../views/ui_kit/ButtonGroupView.vue')
      },
      {
        path: 'cards-basic',
        name: 'CardsBasicView',
        component: () => import('../views/ui_kit/CardsBasicView.vue')
      },
      {
        path: 'cards-hover',
        name: 'CardsHoverView',
        component: () => import('../views/ui_kit/CardsHoverView.vue')
      },
      {
        path: 'carousel',
        name: 'CarouselView',
        component: () => import('../views/ui_kit/CarouselView.vue')
      },
      {
        path: 'collapse',
        name: 'CollapseView',
        component: () => import('../views/ui_kit/CollapseView.vue')
      },
      {
        path: 'countdown',
        name: 'CountdownView',
        component: () => import('../views/ui_kit/CountdownView.vue')
      },
      {
        path: 'date-picker',
        name: 'DatepickerView',
        component: () => import('../views/ui_kit/DatepickerView.vue')
      },
      {
        path: 'dropdowns',
        name: 'DropdownsView',
        component: () => import('../views/ui_kit/DropdownsView.vue')
      },
      {
        path: 'calendar',
        name: 'EventCalendarView',
        component: () => import('../views/ui_kit/EventCalendarView.vue')
      },
      {
        path: 'forms',
        name: 'FormsView',
        component: () => import('../views/ui_kit/FormsView.vue')
      },
      // {
      //   path: 'gallery',
      //   name: 'GalleryView',
      //   component: () => import('../views/ui_kit/GalleryView.vue')
      // },
      {
        path: 'hotspots',
        name: 'HotspotsView',
        component: () => import('../views/ui_kit/HotspotsView.vue')
      },
      {
        path: 'image-comparison-slider',
        name: 'ImageComparisonSliderView',
        component: () => import('../views/ui_kit/ImageComparisonSliderView.vue')
      },
      {
        path: 'input-group',
        name: 'InputGroupView',
        component: () => import('../views/ui_kit/InputGroupView.vue')
      },
      {
        path: 'list-group',
        name: 'ListGroupView',
        component: () => import('../views/ui_kit/ListGroupView.vue')
      },
      {
        path: 'maps',
        name: 'MapsView',
        component: () => import('../views/ui_kit/MapsView.vue')
      },
      {
        path: 'modal',
        name: 'ModalView',
        component: () => import('../views/ui_kit/ModalView.vue')
      },
      {
        path: 'navbar',
        name: 'NavbarView',
        component: () => import('../views/ui_kit/NavbarView.vue')
      },
      {
        path: 'offcanvas',
        name: 'OffcanvasView',
        component: () => import('../views/ui_kit/OffcanvasView.vue')
      },
      {
        path: 'pagination',
        name: 'PaginationView',
        component: () => import('../views/ui_kit/PaginationView.vue')
      },
      {
        path: 'parallax',
        name: 'ParallaxView',
        component: () => import('../views/ui_kit/ParallaxView.vue')
      },
      {
        path: 'pills',
        name: 'PillsView',
        component: () => import('../views/ui_kit/PillsView.vue')
      },
      {
        path: 'placeholders',
        name: 'PlaceholdersView',
        component: () => import('../views/ui_kit/PlaceholdersView.vue')
      },
      {
        path: 'popovers',
        name: 'PopoversView',
        component: () => import('../views/ui_kit/PopoversView.vue')
      },
      {
        path: 'portfolio-components',
        name: 'PortfolioComponentsView',
        component: () => import('../views/ui_kit/PortfolioComponentsView.vue')
      },
      {
        path: 'pricing',
        name: 'PricingView',
        component: () => import('../views/ui_kit/PricingView.vue')
      },
      {
        path: 'progress',
        name: 'ProgressView',
        component: () => import('../views/ui_kit/ProgressView.vue')
      },
      {
        path: 'shop-components',
        name: 'ShopComponentsView',
        component: () => import('../views/ui_kit/ShopComponentsView.vue')
      },
      {
        path: 'social-buttons',
        name: 'SocialButtonsView',
        component: () => import('../views/ui_kit/SocialButtonsView.vue')
      },
      {
        path: 'spinners',
        name: 'SpinnersView',
        component: () => import('../views/ui_kit/SpinnersView.vue')
      },
      {
        path: 'steps',
        name: 'StepsView',
        component: () => import('../views/ui_kit/StepsView.vue')
      },
      {
        path: 'tabs',
        name: 'TabsView',
        component: () => import('../views/ui_kit/TabsView.vue')
      },
      {
        path: 'team',
        name: 'TeamView',
        component: () => import('../views/ui_kit/TeamView.vue')
      },
      {
        path: 'testimonials',
        name: 'TestimonialsView',
        component: () => import('../views/ui_kit/TestimonialsView.vue')
      },
      {
        path: 'toasts',
        name: 'ToastsView',
        component: () => import('../views/ui_kit/ToastsView.vue')
      },
      {
        path: 'tooltips',
        name: 'TooltipsView',
        component: () => import('../views/ui_kit/TooltipsView.vue')
      },
      {
        path: 'video-popup',
        name: 'VideoPopupView',
        component: () => import('../views/ui_kit/VideoPopupView.vue')
      },
      {
        path: 'utilites',
        name: 'UtilitesView',
        component: () => import('../views/ui_kit/UtilitesView.vue')
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/errors/NotFound.vue')
  }
]







const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  // linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If there's a saved position (e.g., when navigating back), use it
      return savedPosition;
    } else {
      // Scroll to the top of the page on route change
      return { top: 0 };
    }
  }
});
// router.beforeEach(() => {
//   window.scrollTo(0, 0)
// })
// router.beforeEach((to, from, next) => {
//   const auth = getAuth();
//   const user = auth.currentUser;

//   if (to.matched.some(record => record.meta.requiresAuth) && !user) {
//     next('/login');
//   } else {
//     next();
//   }
// });

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones
    tag.setAttribute('data-vue-router-controlled', '');

    document.head.appendChild(tag);
  });

  next();
});

export default router
