<template>

    <NavBarVue></NavBarVue>

    
    <AsideMenu></AsideMenu>

    
    <main class="docs-container pt-5">

      <div class="container-fluid">

        <router-view/>

      </div>
    </main>

  </template>
  
  <script>
  import AsideMenu from "@/components/AsideMenu.vue";
  import NavBarVue from "../components/NavbarDocs.vue";
  
  export default {
    name: 'App',
    components: {
      NavBarVue,
      AsideMenu,
    },
  };
  </script>
  
  
  <style>
  </style>
  